<template>
  <div class="form">
    <div class="wrapper">
      <Backlink title="questionnaire" service="credit" backlink="/credit/obligations" />
      <div class="content">
        <div class="content__container">
          <Question
            service="credit"
            name="problem"
            question="question_4"
            successUrl="/credit/about-problems"
            negativeUrl="/credit/delay"
            :droppedSteps="['/credit/about-problems']"
            :negativeMissedSteps="['/credit/about-problems']"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Backlink from '@/components/base/Backlink.vue'
import Question from '@/components/Question.vue'
export default {
  name: 'Credit_problems',
  components: { Backlink, Question }
}
</script>
